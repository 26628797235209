/*
	Dimension by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* BG */

	body.is-preload #bg:before {
		background-color: transparent;
	}

/* Header */

	body.is-preload #header {
		-moz-filter: none;
		-webkit-filter: none;
		-ms-filter: none;
		filter: none;
	}

		body.is-preload #header > * {
			opacity: 1;
		}

		body.is-preload #header .content .inner {
			max-height: none;
			padding: 3rem 2rem;
			opacity: 1;
		}

/* Main */

	#main article {
		opacity: 1;
		margin: 4rem 0 0 0;
	}